<template>
  <div>No applicable renderer found.</div>
</template>

<script lang="ts">
import { defineComponent } from '../../config';

export default defineComponent({
  name: 'unknown-renderer'
});
</script>
